import React from 'react';
import styles from './ArrowButton.module.css';

const ArrowButton = ({ children, type='left', size='35px', onClick}) => {
    return (
        <div className={styles.arrowButton} style={{height: size, width: size}} onClick={onClick}>
            {(type !== 'right') ? (
                <svg viewBox="232.5 232.5 35 35" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M 250 267.5 C 240.335 267.5 232.5 259.665 232.5 250 C 232.5 240.335 240.335 232.5 250 232.5 C 259.665 232.5 267.5 240.335 267.5 250 C 267.5 259.665 259.665 267.5 250 267.5 Z" fill="currentColor"/>
                        <g transform="matrix(1, 0, 0, 1, -19.186791, -0.212486)">
                            <g transform="matrix(1, 0, 0, 1, 19.556298, 0.424478)">
                                <path d="M 260.534 248.532 L 241.059 248.532 L 241.059 251.044 L 260.534 251.044 L 260.534 248.532 Z" fill="white"/>
                                <path d="M 247.144 258.207 L 248.904 256.447 L 242.244 249.788 L 248.904 243.129 L 247.144 241.369 L 238.727 249.788 L 247.144 258.207 Z" fill="white"/>
                            </g>
                        </g>
                    </g>
                </svg>
            ) : (
                <svg viewBox="355.2314 207.661 35 35" xmlns="http://www.w3.org/2000/svg">
                    <g transform="matrix(-1, 0, 0, 1, 441.89143162642176, -0.0000030000004187513696)">
                        <path d="M 69.16 242.661 C 59.495 242.661 51.66 234.826 51.66 225.161 C 51.66 215.496 59.495 207.661 69.16 207.661 C 78.825 207.661 86.66 215.496 86.66 225.161 C 86.66 234.826 78.825 242.661 69.16 242.661 Z" fill="currentColor"/>
                        <g transform="matrix(1, 0, 0, 1, -200.026877, -25.051724)">
                            <g transform="matrix(1, 0, 0, 1, 19.556298, 0.424478)">
                                <path d="M 260.534 248.532 L 241.059 248.532 L 241.059 251.044 L 260.534 251.044 L 260.534 248.532 Z" fill="white"/>
                                <path d="M 247.144 258.207 L 248.904 256.447 L 242.244 249.788 L 248.904 243.129 L 247.144 241.369 L 238.727 249.788 L 247.144 258.207 Z" fill="white"/>
                            </g>
                        </g>
                    </g>
                </svg>
            )}
        </div>
    );
};

export default ArrowButton;