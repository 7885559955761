import React from "react";
import { Link } from 'react-scroll';
import styles from './HomeFooter.module.css';
import Button from '../../../components/Button';
import Text from '../../../components/Text';

const HomeFooter = () => {
    return (
        <div className={styles.homeFooter}>
            <div className={styles.footerText}>
                NO FOSSIL FUELS AT COP29
            </div>
            <div className={styles.footerBtn}>
                <Link to="take-action" smooth={true} duration={500}>
                    <Button>Take action</Button>
                </Link>
            </div>
        </div>
    );
};

export default HomeFooter;
