import React from 'react';
import Citation from '../../../components/Citation';

const OilRings = () => {
    const citationHeader = (
        <div className='floating padding-bottom-150'>
            <img src="/images/env-destr-3.jpg" alt="Enviromental Destruction" />
            <p className='description'>
                Oil rigs on the Caspian Sea.
            </p>
        </div>
    );

    return (
        <Citation 
            className="env-destr-citation max-width-content"
            header={citationHeader}
        >
            HOW CAN WE EXPECT COP29 TO BE A MODEL FOR DEVELOPING A FUTURE THAT <span>STAYS WITHIN LIMITING FOSSIL FUEL EXTRACTION</span> TO MEET 1.5 DEGREES CELSIUS?
        </Citation>
    );
};

export default OilRings;
