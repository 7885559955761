import React from 'react';
import TextWithSlider from '../../../components/TextWithSlider';

const HumanRightsGallery = () => {
    const images = [
        {
          imgURL: "/images/slide1_1.jpg",
          imgAlt: "Azerbaijani law enforcement arrests an activist."
        },
        {
          imgURL: "/images/slide1_2.jpg",
          imgAlt: "Azerbaijani authorities at the door moments before violently arresting the Editor-in-Chief of independent Abzas Media on dubious charges, in front of his family."
        },
        {
          imgURL: "/images/slide1_3.jpg",
          imgAlt: "Azerbaijani law enforcement cracks down on protesters."
        },
        {
          imgURL: "/images/slide1_4.jpg",
          imgAlt: "A women's rights protester is detained by police in Baku."
        },
        {
          imgURL: "/images/slide1_5.jpg",
          imgAlt: "Tofig Yagublu, Azerbaijan's opposition figure, after his arrest by police forces in December 2021."
        }
    ];
    return (
        <div id='gallery-1' className='padding-bottom-150 max-width-content margin-top-150'>
            <TextWithSlider images={images} textAlign='left'>
                <span>
                    {/* Activists and academics who challenge Azerbaijan's environmental policies <b>face severe repression</b>. Emin Milli, a journalist, was repeatedly detained, while academic Gubad Ibadoghlu was arrested in retaliation for his research on corruption in the oil sector. Environmental groups like Ecofront operate under constant legal threats, forcing many members into secrecy or exile. */}
                    The Azerbaijani government <b>exerts tight control over civil society</b>, particularly targeting NGOs and religious organizations that pose a threat to its dominance. The process for registering NGOs is intentionally opaque, with the Ministry of Justice often rejecting applications from organizations that focus on human rights, democracy, or transparency. Even when groups manage to register, they are subjected to constant harassment, including arbitrary audits, freezing of assets, and the imprisonment of leaders on trumped-up charges.
                    <br/>
                    <br/>
                    This crackdown stifles critical voices needed to address the country's environmental issues. In response, international organizations like the Extractive Industries Transparency Initiative (EITI) <b className='colored'>suspended Azerbaijan for restricting civil society</b>, while the US and European Parliament have called for sanctions over the government's human rights abuses, reflecting growing global concern.
                </span>
            </TextWithSlider>
        </div>
    );
};

export default HumanRightsGallery;
