import React from 'react';
import styles from './Button.module.css';

const Button = ({ children, type='primary', onClick }) => {
    type = (type === 'secondary')?type:'primary';
    return (
        <button className={styles.button + ' ' + styles[type]} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;