import React from 'react';
import TextWithPic from '../../../components/TextWithPic';
import TextWithSlider from '../../../components/TextWithSlider';
import ImageSlider from '../../../components/ImageSlider';

const GWGenocide = () => {
    const bigImages = [
        {
          imgURL: "/images/slide2_1.jpg",
          imgAlt: "Vehicles in Artsakh shelled in Azerbaijan's military offensive targeting civilian infrastructure."
        },
        {
          imgURL: "/images/slide2_2.jpg",
          imgAlt: "A kindergarten (pics 1 and 2) and other civilian infrastructure were shelled during Azerbaijan's military offensive in Artsakh."
        },
        {
          imgURL: "/images/slide2_3.jpg",
          imgAlt: "Injured children as a result of Azerbaijan's attacks."
        }
    ];

    const images = [
        {
          imgURL: "/images/slide3_1.jpg",
          imgAlt: "Armenians are evacuating from Artsakh in the wake of indiscriminate shelling by Azerbaijan."
        },
        {
          imgURL: "/images/slide3_2.jpg",
          imgAlt: "A child sleeps on a bag filled with clothes and other belongings as ethnic Armenians gather in the center of Stepanakert."
        },
        {
          imgURL: "/images/slide3_3.jpg",
          imgAlt: "The flow of vehicles was constant, with families loading their belongings on top of their cars."
        },
        {
          imgURL: "/images/slide3_4.jpg",
          imgAlt: "Refugees line up in vehicles near the border town of Kornidzor, Armenia."
        },
        {
          imgURL: "/images/slide3_5.jpg",
          imgAlt: "A man hands bread to a refugee woman from the Artsakh who arrived in the back of a truck in the border village of Kornidzor, Armenia."
        }
    ];

    return (
        <div id='gw-genocide' className='padding-bottom-150 max-width-content'>
            <div className='text-header padding-top-150'>
                Greenwashing genocide
            </div>
            <TextWithPic  
                href="/images/gw-genocide-img1.jpg" 
                imgDescription="A dispossessed Artsakhi family forced to leave their home, walking to the Armenian border." 
                alt="family" textAlign='right'
            >
                <span>
                    Accepting COP29 in Baku not only legitimizes an authoritarian regime seeking international validation but also overlooks an ongoing campaign of <b>genocide against Armenians</b>, framed as a "Green Energy Zone" initiative. 
                    <br/>
                    <br/>
                    This zone includes Artsakh, or “Nagorno-Karabakh,” which is ancestral Armenian land given to Azerbaijan by Stalin during Soviet rule. Azerbaijan has <b>systematically erased Armenian cultural heritage</b> in Artsakh, as it has with many other lands violently claimed from Armenia in the last century.
                </span>
            </TextWithPic>
            <span>
                <br/>
                <div>
                    <ImageSlider navigationType='links'>
                        {bigImages.map((image, index) => {
                            return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                        })}
                    </ImageSlider>
                </div>
                <br/>
            </span>
            <div id='gallery-3'>
                <TextWithSlider images={images} textAlign='left'>
                    <span>
                        Azerbaijan's exploitation <b>extends beyond Artsakh, into occupied Nakhchivan</b>, another historically Armenian region handed to Azerbaijan by the USSR. 
                        <br/>
                        <br/>
                        In both regions, the native Armenians where <b>massacred</b>, and all traces of their <b>cultural heritage were destroyed</b> or repurposed. Azerbaijan denies any Armenian presence in these regions, pushing a nationalist agenda that justifies past and ongoing violence. 
                        <br/>
                        <br/>
                        This <b>historical revisionism</b>, coupled with the dehumanization of Armenians, is pervasive in Azerbaijani media, education, and government narratives.
                    </span>
                </TextWithSlider>
            </div>
            <TextWithPic 
                href="/images/gw-genocide-img2.jpg" 
                imgDescription="Hundreds of vehicles evacuating from Artsakh." 
                alt="road" textAlign='right'
            >
                <span>
                    Most recently, <b>Azerbaijan violated another international ceasefires</b> to displace over <b className='colored'>100,000 Armenians</b> from Artsakh, after learning from BP that the area was rich with natural resources for renewable energy that they could exploit. 
                    <br/>
                    <br/>
                    There is extensive evidence of <b>war crimes</b> committed by Azerbaijani forces, including extrajudicial killings, targeting of civilian infrastructure, and the <b>use of banned munitions, such as white phosphorus,</b> which have caused lasting ecological damage. 
                    <br/>
                    <br/>
                    Despite violating numerous international laws and facing orders from the International Criminal Court, Azerbaijan continues to evade accountability for these actions.
                </span>
            </TextWithPic>
        </div>
    );
};

export default GWGenocide;
