import React, { useState } from 'react';
import styles from './TakeActionBlock.module.css';
import Button from '../Button';
import { StaticEmails, TargetEmails } from './Emails';
import { CCAddress, Subjects, Body } from './EmailParams';

const TakeActionBlock = ({ isOpen }) => {
    const [isActive, setIsActive] = useState(false);

    const flipActiveState = () => {
        isOpen(!isActive);
        setIsActive(!isActive);
    }

    const generateMailtoLink = () => {
        const numOfEmails = 90 - StaticEmails.length;
        let emails = TargetEmails.sort(() => .5 - Math.random()).slice(0, numOfEmails)
        emails = emails.concat(StaticEmails);

        // Get Random Subject from the list
        const randomIndex = Math.floor(Math.random() * Subjects.length);
        const emailSubject = encodeURIComponent(Subjects[randomIndex]);

        // Setup email Body
        const emailBody = encodeURIComponent(Body);


        const mailtoLink = `mailto:?cc=${CCAddress}&bcc=${emails.join(',')}&subject=${emailSubject}&body=${emailBody}`;

        return mailtoLink;
    }

    const takeAction = () => {
        const mailtoLink = generateMailtoLink();
        window.open(mailtoLink);
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                It`s your time to take action
            </div>
            <div className={styles.text}>
                If you got this far, you must be ready to take action!. Click the button below <b style={{ color: 'var(--color-text-blue)' }}>to send a pre-written email</b> to government leaders and COP organizers sharing the demands below that have been established by Armenian activists around the world.
            </div>
            <div className={styles.button}>
                <Button onClick={takeAction}>Take action</Button>
            </div>
            <div className={styles.demandsLink} onClick={flipActiveState}>
                <span className={styles.demandLinkText}>Read our demands</span>
                <span className={styles.demandArrow + (isActive ? (' ' + styles.active) : '')}>
                    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0797 0.0505433L13.0734 1.08207L7.6575 6.70054C7.57072 6.79111 7.46752 6.86299 7.35385 6.91203C7.24018 6.96108 7.11827 6.98633 6.99516 6.98633C6.87204 6.98633 6.75014 6.96108 6.63646 6.91203C6.52279 6.86299 6.41959 6.79111 6.33281 6.70054L0.914063 1.08207L1.90781 0.0515156L6.99375 5.32485L12.0797 0.0505433Z" fill="white" />
                    </svg>
                </span>
            </div>
            {(isActive && (
                <div className={styles.demands}>
                    <ul>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>#FREEARMENIANHOSTAGESS</b>: Speak up for the release of the 23 Armenian hostages confirmed by the Azerbaijani government, and for the 80 to 200 forcibly disappeared since 2020.
                        </li>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>SANCTION AZERBAIJAN</b>: Formally call out the human rights violations, war crimes, and genocide committed by Azerbaijan against the indigenous Artsakh Armenians, and demand justice for victims with sanctions and in International Criminal Court.
                        </li>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>REINFORCE THE RIGHT TO RETURN</b>: Support security and a safe return for forcibly displaced Artsakh Armenians to indigenous lands.
                        </li>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>FREE CIVIL SOCIETY</b>: Demand the release of over 300 political prisoners arbitrarily detained in Azerbaijani prisons, including government critics and opposition, academics and journalists exposing rampant state corruption.
                        </li>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>STOP THE HATE</b>: Demand the protection of Armenian cultural property in occupied Artsakh, and the end to all state-sanctioned Armenophobic references in education and references to "Western Azerbaijan."
                        </li>
                        <li>
                            <b style={{ color: 'var(--color-text-blue)' }}>MAKE COP COUNT</b>: No more COPs in countries keeping illegally detained POWs, political prisoners, civilian hostages, or prisoners of conscience.
                        </li>
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default TakeActionBlock;
