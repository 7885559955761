import React from 'react';
import Top from './Top';
import IntroTexts from './IntroTexts';
import HomeCitation from './HomeCitation';
import HomeFooter from './HomeFooter';

const Home = () => {
    return (
        <div className="page">
            <Top/>
            <IntroTexts/>
            <HomeCitation/>
            <HomeFooter/>
        </div>
    );
};

export default Home;
