export const StaticEmails = [
    'contactenos@cancilleria.gov.co'
]
export const TargetEmails = [
    'aaleyd@imf.org',
    'abhisheik.dhawan@uncdf.org',
    'adelju@wmo.int',
    'pridolfi@worldbank.org',
    'dallen@worldbank.org',
    'isabel.riber@wto.org',
    'ludivine.tamiotti@wto.org',
    'adonia.ayebare@gmail.com',
    'agnchair2022@gmail.com',
    'ajoe@unfccc.int',
    'alacerda@gcfund.org',
    'alexandra.fong@un.org',
    'alimalik.fsp@gmail.com',
    'amamadzhanov@gcfund.org',
    'amelia.stewart@wfp.org',
    'amoehner@unfccc.int',
    'amokssit@wmo.int',
    'animesh.africa@gmail.com',
    'animesh.kumar@un.org',
    'arnaud@un.org',
    'barbara.schoenberg@un.org',
    'benjamin.schachter@un.org',
    'caoki@thegef.org',
    'cassie.flynn@undp.org',
    'castellanos@un.org',
    'castellanos@unglobalcompact.org',
    'cbourgeo@imo.org',
    'chair@ldc-climate.org',
    'chewitt@wmo.int',
    'chouchanicherfane@un.org',
    'claudia.contreras@un.org',
    'cristina.bueti@itu.int',
    'csbi@unfccc.int',
    'csbsta@unfccc.int',
    'ctudenggongbu@unfccc.int',
    'cuba_onu@cubanmission.com',
    'dalila.hamou@wipo.int',
    'dkumar@unfccc.int',
    'dlagdameo@unfccc.int',
    'dmitry.mariyasin@un.org',
    'donlon@un.org',
    'dvesligaj@unfccc.int',
    'ebuckley@imf.org',
    'efida@wmo.int',
    'eisaku.toda@un.org',
    'eisaku.toda@gmail.com',
    'elaine.genser@un.org',
    'elizabeth.paturzo@un.org',
    'embajador_onu@cubanmission.com',
    'emshitima40@gmail.com',
    'eneida.rabdishta@turizmi.gov.al',
    'external-relations@unfccc.int',
    'ezzarqui@un.org',
    'f.perusini@ifad.org',
    'fatumanava@samoanymission.ws',
    'fodhiambo@unfccc.int',
    'germani@un.org',
    'gernot.laganda@wfp.org',
    'edcorrespondence@wfp.org',
    'gjoshi@unfccc.int',
    'gueye@ilo.org',
    'h.h.j.vreuls@minezk.nl',
    'h.paillere@iaea.org',
    'hieber-girardet@un.org',
    'hmaseko@unfccc.int',
    'info.unmissionny@mfaft.gov.jm',
    'ispuscas@iom.int',
    'j.barbiere@unesco.org',
    'jennifer.baumwoll@undp.org',
    'joseeduardo.alatorre@cepal.org',
    'k.isensee@unesco.org',
    'kate.hancock@dcceew.gov.au',
    'kristine.fitzpatrick@un.org',
    'ksmith@unfccc.int',
    'ksuassuna@intracen.org',
    'lcipp@unfccc.int',
    'ldcchairoffice@ldc-climate.org',
    'lpollier@unfccc.int',
    'maierom@who.int',
    'mario.castrogrande@itu.int',
    'martell@un.org',
    'marylene.beau@un.org',
    'mcarmen1865@gmail.com',
    'meg.seki@un.org',
    'a.mishra@unesco.org',
    'c.nordheim@unesco.org',
    'raf.tuts@unhabitat.org',
    'bernhard.barth@un.org',
    'yonetani@unhcr.org',
    'gnarasimhan@unicef.org',
    'ldemel@unicef.org',
    'heba.mariey@un.org',
    'a.metelitsa@unido.org',
    'climatechange@unido.org',
    'manuel.rosini@un.org',
    'nvwede-obahor@undp.org',
    'angus.mackay@unitar.org',
    'agmackay2000@yahoo.com',
    'hanny.cuevabeteta@un.org',
    'ghai1@un.org',
    'sergie@un.org',
    'aikaterinis@unops.org',
    'info@unops.org',
    'dunja.krause@un.org',
    'paramita.dutta@un.org',
    'ram.adwan@unrwa.org',
    'ramikadwan@gmail.com',
    'zissener@ehs.unu.edu',
    'venge.nyirongo@unwomen.org',
    'dglaesser@unwto.org',
    'zurosevic@unwto.org',
    'rruffo@unwto.org',
    'vftrapa@unwto.org',
    'james.hale@upu.int',
    'mistir.uneca@un.org',
    'monicaschlottau@gmail.com',
    'mpereira@iom.int',
    'munir.nabeel@gmail.com',
    'murayac@un.org',
    'nams@un.org',
    'escap-edd@un.org',
    'nikolova@un.org',
    'narregui@imf.org',
    'niklas.hagelberg@un.org',
    'ocb-director@fao.org',
    'officeenv@icao.int',
    'pacie04@yahoo.co.uk',
    'parumugam@unfccc.int',
    'peyre@unfccc.int',
    'press@unfccc.int',
    'ptorres@unfccc.int',
    'rbubniene@unfccc.int',
    'rhoenders@imo.org',
    'rmanandhar@unfccc.int',
    'rosemadiouf@gmail.com',
    'rpayo@unfccc.int',
    'sadekt@un.org',
    'salame1@un.org',
    'samoanymission@outlook.com',
    'santiago.lorenzo@cepal.org',
    'sapatnekar@un.org',
    'secretaria.ejecutiva@cepal.org',
    'secretariat@unfccc.int',
    'secretarytotheboard@gcfund.org',
    'shreya.kumra@un.org',
    'sophie.de.coninck@uncdf.org',
    'sstiell@unfccc.int',
    'syeda.showkat@un.org',
    'takada@un.org',
    'tamar.vanstraten@un.org',
    'tasfias@unops.org',
    'tina.kobilsek@gov.si',
    'tristan.tyrrell@un.org',
    'tristan.tyrrell@cbd.int',
    'secretariat@cbd.int',
    'annie.cung@un.org',
    'annie.cung@cbd.int',
    'ttanaka@icao.int',
    'u4ssc@itu.int',
    'ulises@citma.gob.cu',
    'vgoisferreira@unfccc.int',
    'vtrusca@unfccc.int',
    'w.huang@iaea.org',
    'xscanlon@unccd.int',
    'zhekovasvetlana@gmail.com',
    'zommers@un.org',
    'ministria.mtm@turizmi.gov.al',
    'fatjona.cinaj@turizmi.gov.al',
    'nfp.algeria.unfccc@mae.dz',
    'elayoubimanel@gmail.com',
    'oecc@govern.ad',
    'carles_miquel@govern.ad',
    'silva_cec@yahoo.com.br',
    'dcblack11@gmail.com',
    'digma@mrecic.gov.ar',
    'leh@mrecic.gov.ar',
    'h.simidyan@env.am',
    'unfcccfocalpoint@dcceew.gov.au',
    'vi-1@bmk.gv.at',
    'manfred.kohlbach@bmk.gv.at',
    'rneely-murphy@depp.gov.bs',
    'rochellenewbold@bahamas.gov.bs',
    'info@sce.gov.bh',
    'lsabeel@sce.gov.bh',
    'secretary@moef.gov.bd',
    'ron.goodridge@barbados.gov.bb',
    'yolande.howard@barbados.gov.bb',
    'minproos@mail.belpak.by',
    'department.by@gmail.com',
    'climate@health.fgov.be',
    'peter.wittoeck@environment.belgium.be',
    'ceo.sec@environment.gov.bz',
    'cco.cc@environment.gov.bz',
    'ceo@environment.gov.bz',
    'dgec_mcvdd@cadredevie.bj',
    'wilmongazi@yahoo.fr',
    'twangchuk@moenr.gov.bt',
    'contactanos@planificacion.gob.bo',
    'david.guachalla@planificacion.gob.bo',
    'jallpa@yahoo.com',
    'edson.apaza@planificacion.gob.bo',
    'prognalcc.bolivia@gmail.com',
    'estebanelmer@gmail.com',
    'angelica.ponce@madretierra.gob.bo',
    'nsalasr@rree.gob.bo',
    'patricia.guzman.cabello@gmail.com',
    'kabinetministra@mgr.vladars.rs',
    's.radusin@mgr.vladars.rs',
    'meteo@gov.bw',
    'bgopolang@gov.bw',
    'dclima@itamaraty.gov.br',
    'tulio.andrade@itamaraty.gov.br',
    'bccs@jpm.gov.bn',
    'zaiem.halbi@jpm.gov.bn',
    'stusheva@moew.government.bg',
    'tusheva.nfp@moew.government.bg',
    'edno_gishe@moew.government.bg',
    'borikova.nfp@moew.government.bg',
    'mtee.spcndd@gmail.com',
    'ncom.alan@yahoo.fr',
    'augungenzi@yahoo.fr',
    'camoniz@yahoo.com.br',
    'moe-cabinet@camnet.com.kh',
    'chuop.paris@moe.gov.kh',
    'sum.thy@moe.gov.kh',
    'maohakccd.se@gmail.com',
    'kagonbet@yahoo.fr',
    'marc-andre.lafrance@ec.gc.ca',
    'laurence.ahoussou@ec.gc.ca',
    'climatrca@gmail.com',
    'gildastolakogadou@yahoo.fr',
    'mhthanno@yahoo.fr',
    'ahmatali.env.92@gmail.com',
    'chogara11@hotmail.com',
    'esilvab@minrel.gob.cl',
    'jcordano@minrel.gob.cl',
    'unfccc_china@mfa.gov.cn',
    'nfpcc@mee.gov.cn',
    'sergio.salazar@cancilleria.gov.co',
    'sofia.vargas@cancilleria.gov.co',
    'amihidjay@yahoo.fr',
    'abdelmalis@gmail.com',
    'secretariatministre@ministere-tourisme.gouv.cg',
    'florantinef2bc@gmail.com',
    'secfa@cookislands.gov.ck',
    'tepaeru.herrmann@cookislands.gov.ck',
    'cambioclimatico@minae.go.cr',
    'abonilla@minae.go.cr',
    'klima-unfccc@mingor.hr',
    'visnja.grgasovic@mingor.hr',
    'tmesimeris@environment.moa.gov.cy',
    'nkythreotou@environment.moa.gov.cy',
    'pavel.zamyslicky@mzp.cz',
    'eric_michel_assamoi@yahoo.fr',
    'remymangani1@gmail.com',
    'info@medd.gouv.cd',
    'martinebk@yahoo.co.uk',
    'kefm@kefm.dk',
    'dkfp@kefm.dk',
    'balala_ingenieur@hotmail.fr',
    'dini.omar85@gmail.com',
    'environment@dominica.gov.dm',
    'huntere@dominica.gov.dm',
    'despacho@cambioclimatico.gob.do',
    'm.puig@cambioclimatico.gob.do',
    'cambio.climatico@ambiente.gob.do',
    'milagros.decamps@ambiente.gob.do',
    'consuelo.cruz@ambiente.gob.do',
    'miguel.cearahatton@ambiente.gob.do',
    'dads@cancilleria.gob.ec',
    'jstacey@cancilleria.gob.ec',
    'angel.sandoval@ambiente.gob.ec',
    'unfccc_eg.cccu@yahoo.com',
    'ccu@eeaa.cloud.gov.eg',
    'despacho@ambiente.gob.sv',
    'jlaguardia@ambiente.gob.sv',
    'pmalavo@hotmail.com',
    'dioobiang@gmail.com',
    'josensuendongnsang@gmail.com',
    'tesgsstesfu@gmail.com',
    'kibromaw@gmail.com',
    'info@kliimaministeerium.ee',
    'ps_tourism@gov.sz',
    'khange@hotmail.com',
    'info@mopd.gov.et',
    'abas.mohammed5@gmail.com',
    'fitsum.assefa@mopd.gov.et',
    'clima-eu-unfccc-national-focal-point@ec.europa.eu',
    'tuivanualevu.f@gmail.com',
    'sivendra.michael@gmail.com',
    'marjo.nummelin@gov.fi',
    'finunfccc.ym@gov.fi',
    'benedicte.jenot@developpement-durable.gouv.fr',
    'charlotte.lerat@diplomatie.gouv.fr',
    'yue.dong@developpement-durable.gouv.fr',
    'secretariatconseilclimat@gmail.com',
    'gabon.nfp.unfccc@gmail.com',
    'info@meccnar.gov.gm',
    'mcham@meccnar.gov.gm',
    'kintehsambou9@gmail.com',
    'nino.tkhilava@mepa.gov.ge',
    '405-rl@diplo.de',
    'daniel.benefo@epa.gov.gh',
    'info@ypen.gov.gr',
    'd.niavis@prv.ypeka.gr',
    'i.tsalakanidou@prv.ypeka.gr',
    'registry@tourism.gov.gd',
    'ps@cre.gov.gd',
    'registry@mofa.gov.gd',
    'ps@mofa.gov.gd',
    'mjiturbide@marn.gob.gt',
    'sgrodas@marn.gob.gt',
    'alphayaya2009@yahoo.fr',
    'oumoudoumbouya1@gmail.com',
    'cassamavilus@gmail.com',
    'vpguy2020@gmail.com',
    'arbishop10@gmail.com',
    'arbishop10@yahoo.com',
    'jmckenzie@nre.gov.gy',
    'pbholanath@nre.gov.gy',
    'jamescadet001@gmail.com',
    'rapportistati@sds.va',
    'paoloconversi@hotmail.com',
    'cooperacion@miambiente.gob.hn',
    'despachoministerial@miambiente.gob.hn',
    'subsecretariaambiente@miambiente.gob.hn',
    'klimapolitika@em.gov.hu',
    'veronika.bagi@em.gov.hu',
    'helga.bardadottir@uar.is',
    'sahnk@cag.gov.in',
    'nfpccsecretariat@menlhk.go.id',
    'ldhewanthi@menlhk.go.id',
    'irfocal@yahoo.com',
    'iraqccc@gmail.com',
    'moen.iraq@gmail.com',
    'moen_iraq@yahoo.com',
    'mostafa_mahmood@yahoo.com',
    'yousif80yousif@yahoo.com',
    'megan.skelly@decc.gov.ie',
    'shulin@sviva.gov.il',
    'ayeletr@sviva.gov.il',
    'clea-udg@minambiente.it',
    'fricano.federica@mase.gov.it',
    'omar.alcock@megjc.gov.jm',
    'climate.focal.point@mofa.go.jp',
    'aogu.miyama@mofa.go.jp',
    'belal.shqarin@moenv.gov.jo',
    'shqareen@yahoo.com',
    'k.kense@ecogeo.gov.kz',
    'm.oshurbaev@ecogeo.gov.kz',
    's.sabieva@ecogeo.gov.kz',
    'cs@environment.go.ke',
    'ali.d.mohamed786@gmail.com',
    'psoffice@environment.go.ke',
    'abrahamsingoei@gmail.com',
    'pogola@environment.go.ke',
    'secretary@ob.gov.ki',
    'tekimwau@ob.gov.ki',
    'dg@epa.gov.kw',
    'turgunbaev@mnr.gov.kg',
    'international@mnr.gov.kg',
    'climate@mnr.gov.kg',
    'amphayvanh.oudomdeth@gmail.com',
    'syamphone.s@gmail.com',
    'climatechange@moe.gov.lb',
    'h.mounajed@moe.gov.lb',
    'mokuena.france@gov.ls',
    'nawmaliberia@yahoo.com',
    'm.swidan@pm.gov.ly',
    'info.au@llv.li',
    'nfp_unfccc@llv.li',
    'info@am.lt',
    'vilija.augutaviciene@am.lt',
    'henri.haine@mev.etat.lu',
    'laivao2002@yahoo.fr',
    'evansnjewa@gmail.com',
    'tawongam@yahoo.com',
    'ridzwan.ali@nres.gov.my',
    'farid@nres.gov.my',
    'hartini@nres.gov.my',
    'ahmed.waheed@environment.gov.mv',
    'aedd.environnement@gmail.com',
    'moussayem@yahoo.fr',
    'zantiguibkone@yahoo.fr',
    'david.i.muscat@gov.mt',
    'climatechangeeud.msdec@gov.mt',
    'joseph.f.caruana@gov.mt',
    'clarencesam@gmail.com',
    'elbonteri@gmail.com',
    'climate@environnement.gov.mr',
    'moussaba@environnement.gov.mr',
    'menv@govmu.org',
    'smeeheelaul@govmu.org',
    'rsonea@govmu.org',
    'dgtglobales@sre.gob.mx',
    'focalpointmexico@sre.gob.mx',
    'decemsec.fsm@gmail.com',
    'lu.overhoff@gmail.com',
    'cchanteloube@gouv.mc',
    'contact@mne.gov.mn',
    'odbayar@ymail.com',
    'milica.ojdanic@mepg.gov.me',
    'kabinet@mert.gov.me',
    'nikola.tomasevic@mert.gov.me',
    'kabinet@mfa.gov.me',
    'stanica.andjic@mfa.gov.me',
    'razi@environnement.gov.ma',
    'bz.razi@gmail.com',
    'mta@gov.mz',
    'ebaixo@hotmail.com',
    'agostinhofernando@yahoo.com.br',
    'pmuteyauli@yahoo.co.uk',
    'nauru@un.int',
    'naurumission@nauruun.org',
    'reagan.moses@gmail.com',
    'info@mofe.gov.np',
    'buddi.poudel@gmail.com',
    'govinda.sharma@nepal.gov.np',
    'j.m.keijsper@minezk.nl',
    'icm@mfe.govt.nz',
    'unfccc.nz@mfe.govt.nz',
    'despacho.sccp@sepres.gob.ni',
    'secretario.sccp@sepres.gob.ni',
    'sga@environnement.gouv.ne',
    'info@environment.gov.ng',
    'salisu.dahiru@natccc.gov.ng',
    'josie.tamate@gov.nu',
    't.grncarovska@moepp.gov.mk',
    'dori.moeppgovmk@gmail.com',
    'postmottak@kld.dep.no',
    'hhe@kld.dep.no',
    'icd@ea.gov.om',
    'younis.alhajri@ea.gov.om',
    'iftigilani@yahoo.com',
    'dgun@mofa.gov.pk',
    'ministrymos.rop@gmail.com',
    'minister.mos@palaugov.org',
    'xavier.matsutaro@gmail.com',
    'charmersai@gmail.com',
    'mconcepcion@miambiente.gob.pa',
    'bcanizales@mire.gob.pa',
    'jmartinez@miambiente.gob.pa',
    'fvega@miambiente.gob.pa',
    'lcastrod@miambiente.gob.pa',
    'mpinto@miambiente.gob.pa',
    'ybcastillo@miambiente.gob.pa',
    'pngun@pngmission.org',
    'gwen.sissiou@ccda.gov.pg',
    'gsissiou@gmail.com',
    'dncc@mades.gov.py',
    'ministro@mades.gov.py',
    'ethelestigarribia@gmail.com',
    'carestegui@rree.gob.pe',
    'bquispe@minam.gob.pe',
    'cazurin@rree.gob.pe',
    'rsoto@minam.gob.pe',
    'info@climate.gov.ph',
    'reab@climate.gov.ph',
    'unio.div2@dfa.gov.ph',
    'info@mos.gov.pl',
    'grzegorz.grobicki@msz.gov.pl',
    'info@klimat.gov.pl',
    'katarzyna.wrona@klimat.gov.pl',
    'geral@apambiente.pt',
    'eduardo.santos@apambiente.pt',
    'ana.perez@apambiente.pt',
    'analmarri@mecc.gov.qa',
    'smkubaisi@mofa.gov.qa',
    'climate@mofa.go.kr',
    'jjhsay85@naver.com',
    'sojlee19@mofa.go.kr',
    'cancelaria@mediu.gov.md',
    'galina.norocea@mediu.gov.md',
    'marina.lungu@mediu.gov.md',
    'cabinet.ministru@mmediu.ro',
    'geta.nicodim@mmediu.ro',
    'climate@meteorf.ru',
    'fmunyazikwiye@rema.gov.rw',
    'psagriculture@gov.kn',
    'environment@gov.kn',
    'colincia.levine@gov.kn',
    'anitamontoute.dsd@gmail.com',
    'sustainable.devt@govt.lc',
    'dpnathoniel@gmail.com',
    'jmillerfindlay@svgcpd.com',
    'janeel.miller@gmail.com',
    'noumea@mfat.gov.ws',
    'dipartimentoaffariesteri@pa.sm',
    'beatrice.simoncini@esteri.sm',
    'amilcar_afonso@hotmail.com',
    'esterline.genero@diplomats.com',
    'esterline11@hotmail.com',
    'limaonofre@gmail.com',
    'khalid.abuleif@meim.gov.sa',
    'khalid.almehaid@moenergy.gov.sa',
    'babadrame@gmail.com',
    'kabinet@ekologija.gov.rs',
    'dragana.radulovic@eko.gov.rs',
    'w.agricole@meteo.gov.sc',
    'w.agricole@env.gov.sc',
    'gabrielkpaka@gmail.com',
    'nccs_international@pmo.gov.sg',
    'scriberex@gmail.com',
    'jozef.skultety@enviro.gov.sk',
    'gp.mope@gov.si',
    'htufah@mecdm.gov.sb',
    'minister-office@moecc.gov.so',
    'dg@moecc.gov.so',
    'alosh@moecc.gov.so',
    'sharmarke.dubow@moecc.gov.so',
    'minister@moecc.gov.so',
    'mjkekana@environment.gov.za',
    'globalengagements@environment.gov.za',
    'jcombrink@environment.gov.za',
    'payaijohn2011@gmail.com',
    'buzon-dgoecc@miteco.es',
    'sec@env.gov.lk',
    'info@env.gov.lk',
    'minister@env.gov.lk',
    'dircc@env.gov.lk',
    'chairman@environment.pna.ps',
    'ahmadabuthaher@yahoo.com',
    'kah_kelani@yahoo.com',
    'archive@mofa.pna.ps',
    'obimbashi@mfae.gov.ps',
    'hcenr2005@yahoo.com',
    'ispatterzon@gmail.com',
    'secdir.milieu@rom.gov.sr',
    'mattias.frumerie@regeringskansliet.se',
    'm.climate@regeringskansliet.se',
    'erica.grankvist@regeringskansliet.se',
    'marta.berglund@regeringskansliet.se',
    'lydie-line.paroz@bafu.admin.ch',
    'info@mola.gov.sy',
    'khaledjbour71@gmail.com',
    'office@meteo.tj',
    'bahodursheraliev@gmail.com',
    'saraban@dcce.mail.go.th',
    'pavichk@gmail.com',
    'unfccc.thailand.focalpoint@gmail.com',
    'noonaree@hotmail.com',
    'augusto.mpinto@yahoo.com',
    'pedro.mcosta@nda.gov.tl',
    'a.04sta@gmail.com',
    'julesazakpo7@gmail.com',
    'julesazakpo@yahoo.fr',
    'sakauola@mic.gov.to',
    'kishan.kumarsingh@planning.gov.tt',
    'marie.hinds@planning.gov.tt',
    'boc@mineat.gov.tn',
    'gasmi81_jihene@yahoo.com',
    'mnptm.dcep@gmail.com',
    'bbmnpt@yandex.com',
    'platasi@gov.tv',
    'pepetua@gmail.com',
    'info@iklim.gov.tr',
    'tugba.dincbas@iklim.gov.tr',
    'alfred.okidi64@gmail.com',
    'margathieno@gmail.com',
    'chyzhenko@menr.gov.ua',
    'chyzhenko@gmail.com',
    'info@mepr.gov.ua',
    'maister@mepr.gov.ua',
    'aalremeithi@ead.gov.ae',
    'climatechange@moccae.gov.ae',
    'omar.albraiki@climateenvoy.gov.ae',
    'saood.alnoori@climateenvoy.gov.ae',
    'halhashimi@climateenvoy.gov.ae',
    'iman.ustadi@cop28.com',
    'ewfe.moei@moei.gov.ae',
    'nawal.alhanaee@moei.gov.ae',
    'archieves@moccae.gov.ae',
    'srmebwana@moccae.gov.ae',
    'uk_unfccc@beis.gov.uk',
    'freddy_manyika@yahoo.com',
    'talleyt@state.gov',
    'nfp.unfccc.uruguay@ambiente.gub.uy',
    'national_climate_centre@eco.gov.uz',
    'international.uznature@gmail.com',
    'j.kazbekov@gmail.com',
    'park.suna18@gmail.com',
    'mwaiwai@vanuatu.gov.vu',
    'ksylvain@vanuatu.gov.vu',
    'ybasil@vanuatu.gov.vu',
    'gesline@vanuatu.gov.vu',
    'piccap@vanuatu.com.vu',
    'fiautu@vanuatu.gov.vu',
    'lwilliam@vanuatu.gov.vu',
    'cambioclimatico.minec@gmail.com',
    'hamlin_jordan@mre.gov.ve',
    'dir.asuntosmultilaterales@mppre.gob.ve',
    'oiai.minec@gmail.com',
    'despacho.temasmultilaterales@gmail.com',
    'vietnamcc.secretariat@monre.gov.vn',
    'ttcuong@gmail.com',
    'tcongnguyen90@gmail.com',
    'info@epa-ye.org',
    'abarman94@gmail.com',
    'hedges.tembo@mgee.gov.zm',
    'climatechange@environment.gov.zw',
    'washingtonzhakata@gmail.com',
    'sandra.hanni@iccwbo.org',
    'tessop@climatenetwork.org',
    'victormenotti@gmail.com',
    'rachitaa.dcj@gmail.com',
    'ceris.jones@nfu.org.uk',
    'francesco.brusaporco@wfo-oma.org',
    'hindououmar@gmail.com',
    'grace@tebtebba.org',
    'susanna.israelsson@saamicouncil.net',
    'eileen@cadpi.org',
    'yunus.arikan@iclei.org',
    'wustl@ringofocalpoint.org',
    'melissa.low@nus.edu.sg',
    'bert.dewel@ituc-csi.org',
    'bridget@wedo.org',
    'zukiswa@womengenderclimate.org',
    'gina.cortesv@gmail.com',
    'mwanahamisi@wedo.org',
    'mashkur.isa@gmail.com',
    'romie.niedermayer@klimadelegation.de',
    'valerianebernard@gmail.com',
    'henrik.grape@wcc-coe.org',
    'tdamon12@gmail.com',
    'dishasarkar400@gmail.com',
    'brigham@un.org',
    'alejandrino@un.org',
    'mariana.mellado@un.org',
    'ana.souza@un.org',
    'jpegram@unicef.org',
    'jstephens@unicef.org',
    'Marina.bantsenko@kliimaministeerium.ee',
    'admin@environnement.gov.ma',
    'cop29.pavilion@iclei.org'
];
