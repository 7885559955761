import React from "react";
import { Link } from 'react-scroll';
import styles from './PreGWGenocide.module.css';
import Button from '../../../components/Button';

const PreGWGenocide = () => {
    return (
        <div className={styles.preGWGenocide}>
            <div className={styles.preGWGenocideText}>
                ATTENDING COP29 IN AZERBAIJAN <span style={{ color: 'var(--color-text-blue)' }}>GREENWASHES</span>&nbsp;GENOCIDE
            </div>
            <div className={styles.preGWGenocideBtn}>
                <Link to="take-action" smooth={true} duration={500}>
                    <Button>Take action</Button>
                </Link>
            </div>
        </div>
    );
};

export default PreGWGenocide;
