import React, {useState} from 'react';
import styles from './TakeAction.module.css';
import TakeActionBlock from '../../../components/TakeActionBlock';

const TakeAction = () => {
    const [isActive, setisActive] = useState(false);

    const handleDemandChange = (isOpen) => {
        setisActive(isOpen);
    }

    return (
        <div className={isActive ? styles.extendedContainer : styles.container}>
            <div className={'max-width-content ' + styles.content}>
                <TakeActionBlock isOpen={handleDemandChange} />
            </div>
        </div>
    );
};

export default TakeAction;