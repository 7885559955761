import React from 'react';
import TextWithPic from '../../../components/TextWithPic';
import Citation from '../../../components/Citation';

const HumanRights = () => {
    return (
        <div id='human-rights' className='padding-bottom-150 max-width-content'>
            <div className='text-header margin-top-150'>
                Human rights violations
            </div>
            <TextWithPic href="/images/text_5.jpg" alt="square stub" textAlign='left' imgDescription='Activists protesting in Baku.'>
                <span>
                    Azerbaijan has a <b className="colored">well-documented history of human rights abuses</b>, including the violent suppression of journalists, activists, and political opposition. Amnesty International and Human Rights Watch have condemned the regime's aggressive tactics to <b>silence dissent</b>. 
                    <br/>
                    <br/>
                    Azerbaijan is rated as <b className="colored">the worst country for LGBTQ rights</b> in the European Union (including Russia), by ILGA-Europe. In 2021, the Organized Crime and Corruption Reporting Project (OCCRP) showed use of Israel's <b>Pegasus spyware</b> on Azerbaijani journalists and activists, to monitor and suppress independent media.
                    <br/>
                    <br/>
                    As COP29 approaches, there must be <b>international pressure</b> on Azerbaijan to release political prisoners and prisoners of war. Currently, at least <b className="colored red">300 political prisoners</b> are being held and tortured in Azerbaijani prisons.
                </span>
            </TextWithPic>
            <TextWithPic href="/images/text_6.jpg" alt="square stub" textAlign='right' imgDescription='Azerbaijani police restrain a protester during a rally in front of the parliament in Baku.'>
                <span>
                    Since Ilham Aliyev took power in 2003, <b>corruption has flourished</b>, civil liberties have eroded, and political opposition has been crushed. Journalists are harassed, blackmailed, and imprisoned, with at least <b className="colored red">10 journalists</b> detained since November 2023.
                    <br/>
                    <br/>
                    Human rights organizations have documented Azerbaijan's <b className="colored">widespread abuse of the criminal justice system</b> to stifle freedom of expression. The International and European Federations of Journalists condemn this escalating crackdown, warning of Azerbaijan's growing hostility toward independent media.
                </span>
            </TextWithPic>
            <Citation author='Ibad Bayramov' authorDescription='Son of imprisoned economist exposing corruption in Aliyev regime'>
                COP29 lends legitimacy on the world stage to the government's illegitimate imprisonment of my father. As his health has deteriorated to extreme levels, <span>western governments continue to meet weekly with their Azerbaijani counterparts</span> regarding COP29.<br/>
                Meaningful progress on climate change cannot be achieved in a country where individuals like my father <span>are imprisoned and tortured for speaking out.</span>
            </Citation>
        </div>
    );
};

export default HumanRights;
