import React from 'react';
import ImageSlider from '../ImageSlider'

const TextWithSlider = ({ children, images, textAlign='left'}) => {
    return (
        <div className={'text-with-pic ' + textAlign}>
            <div className='text part'>
                {children}
            </div>
            <div className='pic part'>
                <ImageSlider>
                    {images.map((image, index) => {
                        return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                    })}
                </ImageSlider>
            </div>
        </div>
    );
};

export default TextWithSlider;
