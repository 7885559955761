import React from 'react';

const Text = ({ children, margingAfterBtn=0, className=''}) => {
    return (
        <div className={'text-blck ' + className}>
            <div className='text'>
                {children}
            </div>
        </div>
    );
};

export default Text;
