import React from "react";
import Button from '../../../components/Button';

const Top = () => {
  return (
    <div id="home">
      <div className="header">
        <h1>STOP&nbsp;COP29</h1>
      </div>
      <div className="img_container max-width-content">
        <div className="text-over">
          <div className="cop-location">
            <svg
              width="39"
              height="54"
              viewBox="0 0 39 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 25.65C17.653 25.65 15.8816 24.9388 14.5755 23.673C13.2694 22.4071 12.5357 20.6902 12.5357 18.9C12.5357 17.1098 13.2694 15.3929 14.5755 14.127C15.8816 12.8612 17.653 12.15 19.5 12.15C21.347 12.15 23.1184 12.8612 24.4245 14.127C25.7306 15.3929 26.4643 17.1098 26.4643 18.9C26.4643 19.7864 26.2842 20.6642 25.9342 21.4831C25.5842 22.3021 25.0712 23.0462 24.4245 23.673C23.7778 24.2998 23.0101 24.797 22.1651 25.1362C21.3202 25.4754 20.4146 25.65 19.5 25.65ZM19.5 0C14.3283 0 9.36838 1.99124 5.71142 5.53568C2.05446 9.08012 0 13.8874 0 18.9C0 33.075 19.5 54 19.5 54C19.5 54 39 33.075 39 18.9C39 13.8874 36.9455 9.08012 33.2886 5.53568C29.6316 1.99124 24.6717 0 19.5 0Z"
                fill="#6DA7E1"
              />
            </svg>
            IN <span>BAKU</span>
          </div>
          <span className="text">
            HOW AND WHY TO FIGHT
            <br />
            GREENWASHING AT <u>COP29</u>
            {/* <div style={{'marginTop': '50px'}}>
                <Button>Take action</Button>
            </div> */}
          </span>
        </div>
        <img id="fabric" src="/images/fabric-steam.jpg" alt="fabric" />
      </div>
    </div>
  );
};

export default Top;
