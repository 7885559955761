import React from 'react';
import { Element } from 'react-scroll';
import Home from '../../0_Home'
import WhyBoycot from '../../1_WhyBoycot'
import Champions from '../../2_Champions'
import Greenwashers from '../../3_Greenwashers'
import Support from '../../4_Support'

const Main = () => {
    return (
        <div id='main'>
            {/* Page 0 -- TOP -- Home */}
            <Element name="home">
                <Home/>
            </Element>

            {/* Page 1 -- Why Boycott COP29 */}
            <Element name="why-move">
                <WhyBoycot/>
            </Element>

            {/* Page 2 -- Champions -- For V2 */}
            {/*
            <Element name="champions">
                <Champions/>
            </Element>
            */}

            {/* Page 3 -- Greenwashers -- For V2 */}
            {/*
            <Element name="greenwashers">
                <Greenwashers/>
            </Element>
            */}

            {/* Page 4 -- How to Support? */}
            <Element name="how-to-support">
                <Support/>
            </Element>
        </div>
    );
};

export default Main;
