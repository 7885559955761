import React from 'react';
import Why from './Why';
import HumanRights from './HumanRights';
import HumanRightsGallery from './HumanRightsGallery';
import Text from '../../components/Text';
import EnvDestruction from './EnvDestruction';
import OilRings from './OilRings';
import OilPlatform from './OilPlatform';
import PreGWGenocide from './PreGWGenocide';
import GWGenocide from './GWGenocide';

const WhyBoycot = () => {
    return (
        <div className="page">
            <Why />
            <HumanRights />
            <Text className='second-text-blck'>
                <p className='description max-width-content'>
                    Azerbaijanis protesting the illegal detention of political prisoners in Baku.
                </p>
            </Text>
            <HumanRightsGallery />
            <EnvDestruction />
            <OilRings />
            <OilPlatform />
            <PreGWGenocide />
            <GWGenocide />
        </div>
    );
};

export default WhyBoycot;
