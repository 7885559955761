import React from 'react';
import Citation from '../../../components/Citation';

const HomeCitation = () => {
    return (
        <Citation 
            author='Andrew Stroehlein' 
            authorDescription='European Media and Editorial Director of Human Rights Watch'
            className='max-width-content'
        >
            Putting another <span>rights-abusing petro-autocracy in charge</span> of negotiations addressing the Climate Crisis is simply embarrassing for us as a species.        
        </Citation>
    );
};

export default HomeCitation;
