import React from 'react';
import ArrowButton from '../ArrowButton'
import styles from './ArrowNavigation.module.css';

const ArrowNavigation = ({ children, onNext, onPrev }) => {
    return (
        <div className={styles.container}>
            <ArrowButton type='left' onClick={onPrev}/>
            <div className={styles.index}>{children}</div>
            <ArrowButton type='right' onClick={onNext}/>
        </div>
    );
};

export default ArrowNavigation;