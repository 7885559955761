import React from 'react';
import Text from '../Text';

const Citation = ({ children, header=undefined, author='', authorDescription='', className='' }) => {
    return (
        <div className={'citation ' + className}>
            <Text>
                {
                    header === undefined ?
                        <svg width="60" height="70" viewBox="0 0 60 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.664 0C10.5598 0 0.65625 15.75 0.65625 35V70H22.664V35H7.99218C7.99218 22.05 14.5212 11.6667 22.664 11.6667V0ZM59.3437 0C47.2394 0 37.3359 15.75 37.3359 35V70H59.3437V35H44.6718C44.6718 22.05 51.2008 11.6667 59.3437 11.6667V0Z" fill="#6DA7E1"/>
                        </svg>
                        :
                        header
                }
                <p className='cttn-text'>
                    {children}
                </p>
                <p className='cttn-author'>
                    <b>{author}</b>
                    <br/>
                    {authorDescription}
                </p>
            </Text>
        </div>
    );
};

export default Citation;
