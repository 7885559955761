import React, {useState} from 'react';
import { Link } from 'react-scroll';
import Popup from 'reactjs-popup';
import styles from './Navbar.module.css';
import Button from '../../../components/Button';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const popupContent = (
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
            <div className="modal">
                <a className="close" onClick={closeModal}>
                    &times;
                </a>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae magni
                omnis delectus nemo, maxime molestiae dolorem numquam mollitia, voluptate
                ea, accusamus excepturi deleniti ratione sapiente! Laudantium, aperiam
                doloribus. Odit, aut.
            </div>
        </Popup>
    )

    return (
        <div id="navbar" className={styles.navBar}>
            <div className='wrapper'>
                {/* Page 0 -- TOP -- Home */}
                <div className={styles.home}>
                    <Link to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                </div>

                <div className={styles.middleLinks}>
                    {/* Page 1 -- Why Boycott COP29 */}
                    <div className={styles.navLink}>
                        <Link to="why-move" smooth={true} duration={500}>
                            Why boycott COP29?
                        </Link>
                    </div>

                    {/* Page 2 -- Champions -- For V2 */}
                    {/*
                    <div className='champions'>
                        <Link to="champions" smooth={true} duration={500}>
                            Champions
                        </Link>
                    </div>
                    */}

                    {/* Page 3 -- Greenwashers -- For V2 */}
                    {/*
                    <div className='greenwashers'>
                        <Link to="greenwashers" smooth={true} duration={500}>
                            Greenwashers
                        </Link>
                    </div>
                    */}

                    {/* Page 4 -- How to Support? */}
                    <div className={styles.navLink}>
                        <Link to="how-to-support" smooth={true} duration={500}>
                            How to support?
                        </Link>
                    </div>
                </div>

                {/* <div className='take-action'>
                    Take action
                </div> */}

                <div>
                    <Link to="take-action" smooth={true} duration={500}>
                        <Button>Take action</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
