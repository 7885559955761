import React, { useState, useEffect } from "react";
import styles from './ImageSlider.module.css';
import ArrowNavigation from '../ArrowNavigation';

function ImageSlider({ children, navigationType = 'arrows' }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDone, setSlideDone] = useState(true);
    const [timeID, setTimeID] = useState(null);

    const isLinkNavigation = navigationType === 'links';
    const isButtonNavigation = navigationType !== 'links';

    useEffect(() => {
        if (slideDone) {
            setSlideDone(false);
            setTimeID(
                setTimeout(() => {
                    slideNext();
                    setSlideDone(true);
                }, 5000)
            );
        }
    }, [slideDone]);

    const slideNext = () => {
        setActiveIndex((val) => {
            if (val >= children.length - 1) {
                return 0;
            } else {
                return val + 1;
            }
        });
    };

    const slidePrev = () => {
        setActiveIndex((val) => {
            if (val <= 0) {
                return children.length - 1;
            } else {
                return val - 1;
            }
        });
    };

    const AutoPlayStop = () => {
        if (timeID > 0) {
            clearTimeout(timeID);
            setSlideDone(false);
        }
    };

    const AutoPlayStart = () => {
        if (!slideDone) {
            setSlideDone(true);
        }
    };

    const linkNavigation = (
        <div className={styles.containerLinks}>
            {children.map((item, index) => {
                return (
                    <button
                        key={index}
                        className={
                            activeIndex === index
                                ? (styles["containerLinksSmall"] + " " + styles["containerLinksSmallActive"])
                                : styles["containerLinksSmall"]
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveIndex(index);
                        }}
                    />
                );
            })}
        </div>
    )

    return (
        <div onMouseEnter={AutoPlayStop} onMouseLeave={AutoPlayStart}
            onTouchStart={AutoPlayStop} onTouchEnd={AutoPlayStart}>

            {(isLinkNavigation) && linkNavigation}

            <div className={styles.container}>
                {children.map((item, index) => {
                    return (
                        <div
                            className={styles.sliderItem}
                            style={{ transform: "translateX(" + activeIndex * (-100) + "%)" }}
                            key={index}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>

            <div className={styles.sliderFooter} style={{'justifyContent': isButtonNavigation ? 'space-between' : 'center'}}>
                {isButtonNavigation && <ArrowNavigation onNext={slideNext} onPrev={slidePrev}> {activeIndex + 1} </ArrowNavigation>}
                <div className={styles.sliderImgText}>{children[activeIndex].props.alt}</div>
            </div>
        </div>
    );
}

export default ImageSlider;
