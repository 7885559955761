import React from 'react';
import TextWithPic from '../../../components/TextWithPic';
import WhyFooter from './WhyFooter';

const Why = () => {
    return (
        <div id='why' className='padding-bottom-150'>
            <div className='max-width-content'>
                <div className='text-header margin-top-150'>
                    Why Boycott COP29?
                </div>
                <TextWithPic href="/images/text_3.jpg" alt="square stub" textAlign='right' imgDescription='Freedom in the World 2024 report by Freedom House.'>
                    <span>
                        Azerbaijan has consistently deteriorated in international rankings of corruption and oppression over the last 20 years, <b>scoring only</b><b style={{color: 'var(--color-text-blue)'}}> 7 out of 100</b><b> in Freedom House's 2024 rankings</b> — lower than Russia, China, and Iran. Power remains heavily concentrated in the hands of the Aliyev family, which has ruled since 2003.
                        <br/>
                        <br/>
                        <b>Corruption is rampant,</b> and extensive crackdowns have <b>silenced political opposition, civil liberties, and independent activism.</b>
                        <br/>
                        <br/>
                        Despite these facts, <b>Azerbaijan is hosting COP29 under the guise of a "Peace Summit",</b> just months after forcibly displacing over 100,000 indigenous Armenians from their ancestral lands. They continue to deny this ethnic cleansing, while demolishing Armenian cultural heritage and torturing Armenian hostages.
                    </span>
                </TextWithPic>
                <TextWithPic href="/images/text_4.jpg" alt="square stub" textAlign='left'>
                    <span>
                        <b>The UN's inaction</b> has emboldened Azerbaijan to commit further <b>crimes against humanity,</b> with no consequences for their calculated campaigns of terror.
                        <br/>
                        <br/>
                        Allowing a nation known for <b>violent suppression, genocidal intent, and fossil fuels</b> to host COP29 shows a disturbing alignment with rogue states driven by fossil fuel wealth, while serving as a direct contradiction to the UN's principles of <b style={{color: 'var(--color-text-blue)'}}>human rights, environmental responsibility, and anti-corruption.</b>
                        <br/>
                        <br/>
                        The UN now faces a choice: <b>uphold its foundational values or signal its complicity</b> in Azerbaijan's genocidal acts and misinformation campaigns.
                    </span>
                </TextWithPic>
                <WhyFooter/>
            </div>
        </div>
    );
};

export default Why;
