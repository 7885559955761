import React from 'react';
import { Element } from 'react-scroll';
import HowHelp from './HowHelp';
import TakeAction from './TakeAction';

const Support = () => {
    return (
        <div className="page">
            <HowHelp/>
            <Element name="take-action">
                <TakeAction/>
            </Element>
        </div>
    );
};

export default Support;
