import React from 'react';
import Navbar from './pages/Layout/Navbar';
import Main from './pages/Layout/Main'
import Footer from './pages/Layout/Footer';
import './App.css';

const App = () => {
  return (
    <div id='App'>
      <Navbar/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
